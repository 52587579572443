export const tileSize = 60;
export const windowWidth = tileSize * 12.5;
export const windowHeight = tileSize * 12.5;
export const tilePadding = tileSize * 0.25;
export const border = 10;
export const boardSize = border * 2 + (tileSize + tilePadding) * 9;
export const boardX = (windowWidth - boardSize) / 2;
export const boardY = (windowWidth - boardSize) / 2;
export const playerSize = tileSize * 0.8;
export const wallRadius = 4;

export const wallAnimationDuration = 0.15
export const wallAnimationExtraWidth = 70
export const wallAnimationExtraHeight = 10



export const COLORS = {
	// TILE: "#B6CEC7"
	// TILE: "#D8E0BB"
	TILE: { NORMAL: "#b0bfc7", HOVER: "#d3eced" },
	TILE: { NORMAL: "#A18E80", HOVER: "#A18E80" },

	PLAYERS: {
		0: "white",
		1: "#534B44",
	},

	WALL: {
		// SHADOW: "#F1FAEE"
		// SHADOW: "#E63946"
		// SHADOW: "#285619"
		// SHADOW: "#1D3557"
		// SHADOW: "#374556",
		// PLACED: "#14263e"
		SHADOW: "#534B44",
		PLACED: "#534B44",
		SPAWN: "#95897e"
	},
};
