const util = require("util");
const autoBind = require("auto-bind");

export function makePretty(move) {
	if (move.type === "p") {
		const xStr = String.fromCharCode(97 + move.x);
		const yStr = 9 - move.y;
		return xStr + yStr;
	} else {
		const dir = move.nr >= 64 ? "v" : "h";
		const boardSize = 9;
		const x = move.nr % (boardSize - 1);
		let y = Math.floor(move.nr / (boardSize - 1) + 1);
		if (dir === "v") {
			y -= boardSize - 1;
		}
		const xStr = String.fromCharCode(97 + x);
		const yStr = 9 - y;
		return xStr + yStr + dir;
	}
}
class Cell {
	constructor(x, y) {
		this.x = x;
		this.y = y;

		this.N = null;
		this.E = null;
		this.S = null;
		this.W = null;

		this.notation = makePretty({ type: "p", x: x, y: y });
	}
	getNeighbour() {
		return {
			N: this.N,
			E: this.E,
			S: this.S,
			W: this.W,
		};
	}

	getNeighbourCells() {
		let list = [];

		if (this.E) {
			list.push(this.E);
		}

		if (this.W) {
			list.push(this.W);
		}
		if (this.N) {
			list.push(this.N);
		}
		if (this.S) {
			list.push(this.S);
		}
		return list;
	}

	getCellPawnMove() {
		return { type: "p", x: this.x, y: this.y, notation: this.notation }
	} 

	[util.inspect.custom]() {
		return `{ x: ${this.x}, y: ${this.y})`;
		// return String.fromCharCode(97+this.x) + (9-this.y);
	}
}
class Player {
	constructor(nr, initialPos, wallsLeft) {
		this.nr = nr;
		this.pos = initialPos;
		this.wallsLeft = wallsLeft;
	}

	// [util.inspect.custom]() {
	//     return `{ x: ${this.x}, y: ${this.y})`;
	//     // return String.fromCharCode(97+this.x) + (9-this.y);
	// }
}

export class Quoridor {
	constructor() {
		this.boardSize = 9;
		this.placedWalls = [];

		this.board = this.initBoard();
		this.legalWalls = this.generateWalls();
		this.overlappingWalls = this.generateOverlappingWalls();

		const p1 = new Player(0, this.getCell(4, 8), 10);
		const p2 = new Player(1, this.getCell(4, 0), 10);
		this.players = [p1, p2];
		this.currentPlayer = p1;
		autoBind(this);
	}

	initBoard() {
		let board = [];
		for (let y = 0; y < this.boardSize; y++) {
			board.push([]);
			for (let x = 0; x < this.boardSize; x++) {
				const cell = new Cell(x, y);
				board[y].push(cell);
			}
		}

		// Neighbours

		// Top-left
		board[0][0].E = board[0][1];
		board[0][0].S = board[1][0];

		// Top-right
		board[0][8].W = board[0][7];
		board[0][8].S = board[1][8];

		// First row
		for (let x = 1; x < this.boardSize - 1; x++) {
			board[0][x].W = board[0][x - 1];
			board[0][x].E = board[0][x + 1];
			board[0][x].S = board[1][x];
		}

		// Middle rows
		for (let y = 1; y < this.boardSize - 1; y++) {
			for (let x = 1; x < this.boardSize - 1; x++) {
				board[y][x].W = board[y][x - 1];
				board[y][x].E = board[y][x + 1];
				board[y][x].S = board[y + 1][x];
				board[y][x].N = board[y - 1][x];
			}
		}

		// First column
		for (let y = 1; y < this.boardSize - 1; y++) {
			board[y][0].N = board[y - 1][0];
			board[y][0].E = board[y][1];
			board[y][0].S = board[y + 1][0];
		}

		// Bottom-left
		board[8][0].N = board[7][0];
		board[8][0].E = board[8][1];

		// Bottom-right
		board[8][8].N = board[7][8];
		board[8][8].W = board[8][7];

		// Last row
		for (let x = 1; x < this.boardSize - 1; x++) {
			board[8][x].W = board[8][x - 1];
			board[8][x].E = board[8][x + 1];
			board[8][x].N = board[7][x];
		}

		// Last column
		for (let y = 1; y < this.boardSize - 1; y++) {
			board[y][8].N = board[y - 1][8];
			board[y][8].W = board[y][7];
			board[y][8].S = board[y + 1][8];
		}

		return board;
	}

	setupPosition(moves) {
		for (const move of moves) {
			this.tryMove(move);
		}
	}

	getCell(x, y) {
		return this.board[y][x];
	}

	makePretty(move) {
		if (move.type === "p") {
			const xStr = String.fromCharCode(97 + move.x);
			const yStr = 9 - move.y;
			return xStr + yStr;
		} else {
			const x = move.nr % (this.boardSize - 1);
			let y = Math.floor(move.nr / (this.boardSize - 1) + 1);
			if (move.dir === "v") {
				y -= this.boardSize - 1;
			}
			const xStr = String.fromCharCode(97 + x);
			const yStr = 9 - y;
			return xStr + yStr + move.dir;
		}
	}

	makeUgly(move) {
		const x = move[0].charCodeAt(0) - 97;
		const y = this.boardSize - 1 - parseInt(move[1]);
		if (move.length === 2) {
			return { type: "p", x: x, y: y };
		}
		if (move.length === 3) {
			let nr = y * (this.boardSize - 1) + x;
			if (move[2] === "v") {
				nr += Math.pow(this.boardSize - 1, 2);
			}
			return { type: "w", nr: nr, dir: move[2] };
		}
	}

	getMoves() {
		let moves = [];

		const currentP = this.currentPlayer;
		// console.log('currentP:', currentP);
		const otherP = this.otherPlayer();

		const currentCell = this.getCell(currentP.pos.x, currentP.pos.y);
		const otherCell = this.getCell(otherP.pos.x, otherP.pos.y);

		// pawn moves
		let pawnMoves = this.getPawnMoves();
		// wall moves
		let wallMoves = [];
		// const legalWalls = [];
		if (currentP.wallsLeft > 0) {

			for (let index = 0; index < this.legalWalls.length; index++) {
				const wall = this.legalWalls[index];
				if (wall.placed || !wall.legal) {
					continue;
				}
				this.tryMove(wall);
				if (this.pathExists(currentP) && this.pathExists(otherP)) {
					wallMoves.push(wall);
				}
				this.removeWall(wall);
			}
		}
		// this.legalWalls.forEach(wall => {
		//     console.log('wall:', wall);
		//     this.tryMove(wall)
		//     if (this.pathExists(currentP) && this.pathExists(otherP)) {
		//         wallMoves.push(wall)
		//     }
		//     this.removeWall(wall);
		// });

		// console.log('[...pawnMoves, ...wallMoves]:', [...pawnMoves, ...wallMoves]);
		// this.getPlacedWalls();

		return [...pawnMoves, ...wallMoves];
	}

	getPawnMoves() {
		const currentP = this.currentPlayer;
		const otherP = this.otherPlayer();
		const currentCell = this.getCell(currentP.pos.x, currentP.pos.y);
		const enemyCell = this.getCell(otherP.pos.x, otherP.pos.y);

		let pawnMoves = currentCell.getNeighbourCells().map((cell) => ({
			type: "p",
			x: cell.x,
			y: cell.y,
			notation: cell.notation,
		}));

		// Jumping
		if (Math.abs(currentCell.x - enemyCell.x) + Math.abs(currentCell.y - enemyCell.y) === 1) {
			if (currentCell.y - enemyCell.y === 1 && currentCell.N) {
				if (enemyCell.N) {
					pawnMoves.push(enemyCell.N.getCellPawnMove());
				} else {
					if (enemyCell.E) {
						pawnMoves.push(enemyCell.E.getCellPawnMove());
					}
					if (enemyCell.W) {
						pawnMoves.push(enemyCell.W.getCellPawnMove());
					}
					pawnMoves = pawnMoves.filter((move) => move.x !== currentCell.N.x || move.y !== currentCell.N.y);
				}
			} else if (currentCell.y - enemyCell.y === -1 && currentCell.S) {
				if (enemyCell.S) pawnMoves.push(enemyCell.S.getCellPawnMove());
				else {
					if (enemyCell.E) {
						pawnMoves.push(enemyCell.E.getCellPawnMove());
					}
					if (enemyCell.W) {
						pawnMoves.push(enemyCell.W.getCellPawnMove());
					}
					pawnMoves = pawnMoves.filter((move) => move.x !== currentCell.S.x || move.y !== currentCell.S.y);
				}
			} else if (currentCell.x - enemyCell.x === 1 && currentCell.W) {
				if (enemyCell.W) {
					pawnMoves.push(enemyCell.W.getCellPawnMove());
				} else {
					if (enemyCell.N) {
						pawnMoves.push(enemyCell.N.getCellPawnMove());
					}
					if (enemyCell.S) {
						pawnMoves.push(enemyCell.S.getCellPawnMove());
					}
					pawnMoves = pawnMoves.filter((move) => move.x !== currentCell.W.x || move.y !== currentCell.W.y);
				}
			} else if (currentCell.x - enemyCell.x === -1 && currentCell.E) {
				if (enemyCell.E) {
					pawnMoves.push(enemyCell.E.getCellPawnMove());
				} else {
					if (enemyCell.N) {
						pawnMoves.push(enemyCell.N.getCellPawnMove());
					}
					if (enemyCell.S) {
						pawnMoves.push(enemyCell.S.getCellPawnMove());
					}
					pawnMoves = pawnMoves.filter((move) => move.x !== currentCell.E.x || move.y !== currentCell.E.y);
				}
			}
			pawnMoves = pawnMoves.filter((move) => move.x !== enemyCell.x || move.y !== enemyCell.y);
		}

		// if (Math.abs(currentCell.x - enemyCell.x) + Math.abs(currentCell.y - enemyCell.y) == 1) {
		//     const delta = { x: currentCell.x - enemyCell.x, y: currentCell.y - enemyCell.y }
		//     const jumpCell = { x: currentCell.x - 2 * delta.x, y: currentCell.y - 2 * delta.y}
		// }

		return pawnMoves;
	}

	getWallNr(x, y, dir) {
		let nr = y * (this.boardSize - 1) + x;
		if (dir === "v") {
			nr += (this.boardSize - 1) * (this.boardSize - 1);
		}
		return nr;
	}

	generateWalls() {
		const legalWalls = [];
		for (let y = 0; y < this.boardSize - 1; y++) {
			for (let x = 0; x < this.boardSize - 1; x++) {
				const nr = this.getWallNr(x, y, "h");
				const notation = this.makePretty({ nr: nr, dir: "h" });
				legalWalls.push({ type: "w", nr: nr, dir: "h", notation: notation, legal: true, placed: false });
			}
		}
		for (let y = 0; y < this.boardSize - 1; y++) {
			for (let x = 0; x < this.boardSize - 1; x++) {
				const nr = this.getWallNr(x, y, "v");
				const notation = this.makePretty({ nr: nr, dir: "v" });
				legalWalls.push({ type: "w", nr: nr, dir: "v", notation: notation, legal: true, placed: false });
			}
		}

		return legalWalls;
	}

	tryMove(move) {
		if (this.isPawnMove(move)) {
			this.currentPlayer.pos = this.getCell(move.x, move.y);
		} else {
			if (this.currentPlayer.wallsLeft === 0) {
				throw "Error: No walls left"
			}
				

			this.currentPlayer.wallsLeft -= 1;

			// if (move.nr === 0) {
			//     console.log("BEFORE")
			//     console.log(this.legalWalls)
			// }
			// console.log("this.legalWalls[move.nr].legal", this.legalWalls[move.nr].legal);

			// Remove walls that will now be illegal due to overlap
			this.legalWalls[move.nr].legal = false;
			this.legalWalls[move.nr].placed = true;
			// TODO: remove overlapping walls here

			// console.log("move", move);
			// console.log("this.overlappingWalls[move.nr]", this.overlappingWalls[move.nr]);

			this.overlappingWalls[move.nr].forEach((wall) => {
				this.legalWalls[wall.nr].legal = false;
			});

			// Remove the relevant pawn moves
			const blockedCells = this.getCellsWallWillBlock(move);
			blockedCells.forEach((obj) => {
				const cell = this.getCell(obj.x, obj.y);
				switch (obj.dir) {
					case "N":
						cell.N = null;
						break;
					case "E":
						cell.E = null;
						break;
					case "S":
						cell.S = null;
						break;
					case "W":
						cell.W = null;
						break;
					default:
						break;
				}
				// cell.getNeighbour()[obj.dir] = null;
			});
		}
		this.currentPlayer = this.otherPlayer();
	}

	move(move) {
		console.log("HELLOO?")
		const legalMoves = this.getMoves();
		// console.log("legalMoves", legalMoves.length);
		// console.log("actualLegalMoves", actualLegalMoves.length);
		// console.log(`this.getPlacedWalls()`, this.getPlacedWalls())
		// console.log("this.legalWalls[move.nr].legal", this.legalWalls[move.nr]);
		// console.log("this.legalWalls AFTERMATH", this.legalWalls);

		if (
			(move.type === "w" && this.legalWalls[move.nr].legal) ||
			(move.type === "p" && legalMoves.find((m) => m.x === move.x && m.y === move.y))
		) {
			// console.log(`this.getPlacedWalls BEFORE`, this.getPlacedWalls())
			this.tryMove(move);
			// console.log('moved:', move);
			// console.log("this.legalWalls", this.legalWalls);
		} else {
			// console.log('legalMoves:', legalMoves);
			// console.log(this.board);
			console.log("this.legalWalls:", this.legalWalls);
			throw `Illegal move: move`;
		}
	}

	movePretty(move) {
		const uglyMove = this.makeUgly(move);
		this.move(uglyMove);
	}

	tryMovePretty(move) {
		const uglyMove = this.makeUgly(move);
		this.tryMove(uglyMove);
	}

	otherPlayer() {
		return this.players[1 - this.currentPlayer.nr];
	}

	isPawnMove(move) {
		return move.type === "p";
	}

	isWallMove(move) {
		return move.type === "w";
	}

	getLowerLeftCellOfWall(wall) {
		const x = wall.nr % (this.boardSize - 1);
		let y = Math.floor(wall.nr / (this.boardSize - 1)) + 1;
		if (wall.dir === "v") {
			y -= this.boardSize - 1;
		}

		return this.getCell(x, y);
	}

	getCellsWallWillBlock(wall) {
		let blockedCells = [];
		const cell = this.getLowerLeftCellOfWall(wall);

		if (wall.dir === "h") {
			blockedCells.push({ x: cell.x, y: cell.y, dir: "N" });
			blockedCells.push({ x: cell.x, y: cell.y - 1, dir: "S" });
			blockedCells.push({ x: cell.x + 1, y: cell.y, dir: "N" });
			blockedCells.push({ x: cell.x + 1, y: cell.y - 1, dir: "S" });
		} else {
			blockedCells.push({ x: cell.x, y: cell.y, dir: "E" });
			blockedCells.push({ x: cell.x + 1, y: cell.y, dir: "W" });
			blockedCells.push({ x: cell.x, y: cell.y - 1, dir: "E" });
			blockedCells.push({ x: cell.x + 1, y: cell.y - 1, dir: "W" });
		}

		return blockedCells;
	}

	isWinningcell(player, cell) {
		if (player.nr === 0) {
			return cell.y === 0;
		}
		if (player.nr === 1) {
			return cell.y === 8;
		}
	}

	pathExists(player) {
		let start = player.pos;
		let queue = [start];
		let visited = new Set();
		visited.add(start);
		while (queue.length > 0) {
			const node = queue.pop();
			const neighbours = node.getNeighbourCells(node);

			for (const neighbour of neighbours) {
				if (!visited.has(neighbour)) {
					queue.push(neighbour);
					visited.add(neighbour);
					if (this.isWinningcell(player, neighbour)) {
						return 1;
					}
				}
			}
		}
		return 0;
	}

	removeWall(move) {
		// if (this.legalWalls[move.nr].placed === true) {
		//     console.log("wtf: ", move)
		//     console.log(move.placed)
		//     console.log("real:")
		//     console.log(this.legalWalls[move.nr].placed)
		// }

		// if (move.nr === 0) {
		//     console.log("WUT")
		// }

		// if (move.nr === 64) {
		//     console.log("WAT")
		// }
		this.currentPlayer = this.otherPlayer();
		this.currentPlayer.wallsLeft += 1;
		this.legalWalls[move.nr].legal = true;
		this.legalWalls[move.nr].placed = false;
		const blockedCells = this.getCellsWallWillBlock(move);

		blockedCells.forEach((obj) => {
			const cell = this.getCell(obj.x, obj.y);
			switch (obj.dir) {
				case "N":
					cell.N = this.getNeighbourCell(cell, obj.dir);
					break;
				case "E":
					cell.E = this.getNeighbourCell(cell, obj.dir);
					break;
				case "S":
					cell.S = this.getNeighbourCell(cell, obj.dir);
					break;
				case "W":
					cell.W = this.getNeighbourCell(cell, obj.dir);
					break;
				default:
					break;
			}
		});

		this.overlappingWalls[move.nr].forEach((wall) => {
			if (this.isWallLegal(wall)) {
				// if (wall.nr === 0) {
				//     console.log("move", move);
				//     console.log("wall", wall);
				//     console.log("this.overlappingWalls[move.nr]", this.overlappingWalls[move.nr]);
				//     console.log("this.overlappingWalls[wall.nr]", this.overlappingWalls[wall.nr]);

				//     for (const w of this.overlappingWalls[wall.nr]) {

				//         console.log("lol")
				//         console.log(this.legalWalls[w.nr])
				//         console.log(this.legalWalls[w.nr].placed)
				//         console.log("this.isWallLegal(wall)", this.isWallLegal(wall));

				//     };
				// }

				this.legalWalls[wall.nr].legal = true;
			}
		});
	}

	isWallLegal(wall) {
		// console.log("wall123", wall);
		// console.log("this.overlappingWalls[wall.nr]", this.overlappingWalls[wall.nr]);

		for (const w of this.overlappingWalls[wall.nr]) {
			// if (wall.nr === 64) {
			//     console.log("Halla")
			//     console.log(this.legalWalls[w.nr])
			// }
			if (this.legalWalls[w.nr].placed) {
				return false;
			}
		}
		return true;
	}

	getNeighbourCell(cell, dir) {
		let x = cell.x;
		let y = cell.y;

		switch (dir) {
			case "N":
				y -= 1;
				break;
			case "E":
				x += 1;
				break;
			case "S":
				y += 1;
				break;
			case "W":
				x -= 1;
				break;
		}

		return this.getCell(x, y);
	}

	isLegalMove(move) {
		if (this.getMoves().find((m) => m.x === move.x && m.y == move.y)) {
			return true;
		}
		return false;
	}

	printPrettyPositions() {
		const xStr = String.fromCharCode(97 + this.players[0].pos.x);
		const yStr = 9 - this.players[0].pos.y;
		console.log("p1:", xStr + yStr);

		const xStr2 = String.fromCharCode(97 + this.players[1].pos.x);
		const yStr2 = 9 - this.players[1].pos.y;
		console.log("p2:", xStr2 + yStr2);
		console.log("");
	}

	generateOverlappingWalls() {
		const overlappingWalls = [];

		for (let i = 0; i < Math.pow(this.boardSize - 1, 2); i++) {
			const w = [];
			if (i % 8 !== 0) {
				w.push({ type: "w", nr: i - 1, dir: "h" });
			}
			if (i % 8 !== 7) {
				w.push({ type: "w", nr: i + 1, dir: "h" });
			}
			w.push({ type: "w", nr: i + 64, dir: "v" });
			overlappingWalls.push(w);
		}

		for (let i = Math.pow(this.boardSize - 1, 2); i < 2 * Math.pow(this.boardSize - 1, 2); i++) {
			const w = [];
			if (i >= 64 + 8) {
				w.push({ type: "w", nr: i - 8, dir: "v" });
			}
			if (i <= 119) {
				w.push({ type: "w", nr: i + 8, dir: "v" });
			}
			w.push({ type: "w", nr: i - 64, dir: "h" });
			overlappingWalls.push(w);
		}
		// console.log("overlappingWalls", overlappingWalls);
		return overlappingWalls;
	}

	getPlacedWalls() {
		const placedWalls = this.legalWalls.filter((wall) => wall.placed);
		this.placedWalls = placedWalls;
		return placedWalls;
	}

	getLegalWalls() {
		const legalWalls = this.legalWalls.filter((wall) => wall.legal === true && !wall.placed);
		return legalWalls;
	}
}

// const quoridor = new Quoridor();
// quoridor.initBoard();

// console.log('quoridor.legalWalls:', quoridor.legalWalls);

// let m1 = quoridor.makeUgly("a8v")
// console.log('m1:', m1);
// m1 = quoridor.makePretty(m1)
// console.log('m1:', m1);

// const square = quoridor.getLowerLeftCellOfWall(m1)
// console.log('square:', square);

// console.log();
// console.log('quoridor.getPawnMoves():', quoridor.getPawnMoves());

// console.log('moves:', quoridor.getMoves());

// quoridor.tryMovePretty("a8v");

// console.log(quoridor.getPlacedWalls())
// console.log('getPlacedWalls:', quoridor.getPlacedWalls());
// quoridor.tryMovePretty("d1h");
// console.log();
// console.log('quoridor.getPawnMoves():', quoridor.getPawnMoves());
// console.log('getPlacedWalls:', quoridor.getPlacedWalls());
// quoridor.printPrettyPositions();
// console.log("SHOULD ONLY PRINT THIS ONCE")

// const moves = quoridor.getMoves();
// console.log('moves:', moves);

// const illegalMoves = quoridor.legalWalls.filter((move) => move.legal === false || move.placed === true)
// console.log("illegalMoves", illegalMoves);
