import React from "react";
import QuoridorGame from "./game";
import { QuoridorBoard } from "./board";
import { SocketIO } from "boardgame.io/multiplayer";
import { Client } from "boardgame.io/react";
import { Local } from "boardgame.io/multiplayer";

export const Match = ({ match, nickname, credentials }) => {
	console.log("match", match);

	// if (!match || !credentials || !nickname) return;

	console.log("match", match);
	console.log("nickname", nickname);
	console.log("QuoridorGame", QuoridorGame);

	const loadingComponent = () => (
		<div>
			<h2>Waiting for opponent...</h2>
		</div>
	);

	let { protocol, hostname, port } = window.location;
	if (hostname === "localhost") {
		port = 8020;
	}

	const server = `${protocol}//${hostname}:${port}`;
	const QuoridorClient = Client({
		game: QuoridorGame,
		board: QuoridorBoard,
		numPlayers: 2,
		multiplayer: SocketIO({ server: server }),
		debug: false,
		loading: loadingComponent,
	});
	console.log("QuoridorClient", QuoridorClient);

	const IDDDD = match.players.find((player) => player.name === nickname);
	console.log("IDDDD", IDDDD);
	console.log("match.matchID", match.matchID);

	console.log("credentials", credentials);

	let playerId;
	if (IDDDD) {
		playerId = "" + IDDDD.id;
	} else {
		playerId = undefined;
	}

	return (
		<div>
			<QuoridorClient credentials={credentials} matchID={match.matchID} playerID={playerId} />
		</div>
	);
};
