import React, { useState } from "react";
import { ThemeProvider, createMuiTheme } from "@material-ui/core";
import logo from "./logo.svg";
import "./App.css";
import { Main } from "./Components/Quoridor/main";
import { LandingPage } from "./Components/Quoridor/landingpage";
import { Lobby } from "./Components/Quoridor/Lobby/Lobby";
import { Match } from "./Components/Quoridor/Match";
import { PageContext } from "./Components/Quoridor/context";

function App() {
	const darkTheme = createMuiTheme({
		palette: {
			type: "dark",
		},
	});

	const [page, setPage] = useState("landing");
	const [nickname, setNickname] = useState("Gunnar");
	const [match, setMatch] = useState(null);
	const [credentials, setCredentials] = useState(null);

	return (
		<PageContext.Provider value={[page, setPage]}>
			<ThemeProvider theme={darkTheme}>
				<div className="App">
					<header className="App-header">
						{page === "landingg" && <Main />}
						{page === "landing" && (
							<LandingPage
								enterLobby={(nickname) => {
									setNickname(nickname);
									setPage("lobby");
								}}
							/>
						)}
						{page === "lobby" && (
							<Lobby
								nickname={nickname}
								startMatch={(match) => {
									setMatch(match);
									setPage("match");
								}}
								setCredentials={setCredentials}
								credentials={credentials}
							/>
						)}
						{page === "match" && <Match nickname={nickname} match={match} credentials={credentials} />}
					</header>
				</div>
			</ThemeProvider>
		</PageContext.Provider>
	);
}

export default App;
