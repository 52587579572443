import React, { useState } from "react";
import { Button, TextField } from "@material-ui/core";
import Freeboard from "../../Assets/freeboard.png"; // with import
import "./styles.css";
import Zoom from "react-reveal/Zoom"; // Importing Zoom effect
import Rotate from "react-reveal/Rotate"; // Importing Zoom effect
import Fade from "react-reveal/Fade";
import Jump from "react-reveal/Jump";
import { BsFacebook } from "react-icons/bs";


export const LandingPage = ({ enterLobby }) => {
	const [nickname, setNickname] = useState("");
	return (
		<div>
			<div className="landing-page">
				{/* <Rotate> */}
				<Jump>
					<img className="landing-page__freeboard" src={Freeboard} alt="Quoridor board" />
				</Jump>
				{/* </Rotate> */}
				<Fade>
					<Zoom>
						<div className="landing-page__right">
							<h1 className="landing-page__title">Quoridor.io</h1>
							<TextField
								value={nickname}
								onChange={(e) => setNickname(e.target.value)}
								className="landing-page__nickname"
								id="outlined-basic"
								label="Nickname"
								variant="outlined"
							/>
							<Button
								onClick={() => {
									if (nickname) {
										enterLobby(nickname);
									}
								}}
								className="landing-page__button"
								variant="contained"
								color="secondary"
							>
								Play
							</Button>
						</div>
					</Zoom>
				</Fade>
			</div>
			<div className="landing-page__facebook">
				<span style={{ fontSize: "8px", color: "red", margin: "0 0 5px 15px"}}>NEW</span>
				<BsFacebook onClick={() => window.open('https://www.facebook.com/Quoridorio-103190198961367','_blank')} />
			</div>
		</div>
	);
};
