import React, { useState, useEffect, useRef, useContext } from "react";
import "./board.css";
import { Stage, Layer, Rect, Circle, Text } from "react-konva";
import * as CONSTS from "./consts";
import { makePretty } from "./quoridor";
import { Quoridor } from "./quoridor";
import { Button } from "@material-ui/core";
import { PageContext } from "./context";
import { BsPerson, BsPersonFill, BsFillChatSquareFill, BsFlag } from "react-icons/bs";
import { GiBrickWall } from "react-icons/gi";
import useSound from 'use-sound';
import wallSound from '../../Assets/wall2.mp3';
import pawnSound from '../../Assets/wall3.mp3';
import winSound from '../../Assets/win.mp3';
import { TextField, Container } from "@material-ui/core";


export const QuoridorBoard = ({ G, ctx, moves, playerID, isActive, isMultiplayer, matchData, sendChatMessage, chatMessages, log, events }) => {
	// const tiles = [];
	// const walls = [];
	const [walls, setWalls] = useState([]);
	// const players = [];

	const BOARD_SIZE = 9;

	const [tiles, setTiles] = useState([]);
	const [players, setPlayers] = useState([]);

	const [shadowWall, setShadowWall] = useState(null);
	const [pawnMove, setPawnMove] = useState(null);
	const [hoverTile, setHoverTile] = useState({});
	const [legalMoves, setLegalMoves] = useState([]);
	const [gamePlayers, setGamePlayers] = useState([]);
	const [pawnMoves, setPawnMoves] = useState([]);
	const [placedWalls, setPlacedWalls] = useState([]);

	const [playWallSound] = useSound(wallSound, { volume: 0.4 });
	const [playPawnSound] = useSound(pawnSound, { volume: 0.4 });
	const [playWinSound] = useSound(winSound, { volume: 0.4 });

	const ref = useRef(null);
	const ref2 = useRef(null);
	const refs = [ref, ref2];
	const [wallRef, setWallRef] = useState(null);

	const [_, setPage] = useContext(PageContext);
	const [flip, setFlip] = useState(playerID === "1");
	const [chat, setChat] = useState("");

	const [moveNr, setMoveNr] = useState(0);

	// const chatMessages = [
	// 	{ id: 'foo', sender: '0', payload: 'Ready to play?' },
	// 	{ id: 'bar', sender: '1', payload: 'Let’s go!' },
	// 	{ id: 'foo2', sender: '2', payload: 'Ready to play?' },
	// 	{ id: 'bar2', sender: '3', payload: 'Let’s go!' },
	//   ]

	useEffect(() => {
		drawTiles();
	}, []);

	useEffect(() => {
		drawPlayers(gamePlayers)
	}, [gamePlayers]);

	useEffect(() => {
		drawWalls(placedWalls)
	}, [placedWalls]);

	useEffect(() => {
		if (G.moves.length === moveNr && G.moves.length > 0) {
			return
		} else {
			setMoveNr(G.moves.length)
		}
		// console.log("G.moves in beginning")
		// console.log("ctx", ctx);
		// console.log("moves", moves);
		// console.log("isActive", isActive);

		const game = new Quoridor();
		game.setupPosition(G.moves);

		let receivedMoves = game.getMoves()
		let receivedPlayers = game.players
		let receivedPlacedWalls = game.getPlacedWalls()
		let receivedPawnMoves = game.getPawnMoves()

		if (flip) {
			flipBoard(receivedMoves, receivedPlayers, receivedPlacedWalls, receivedPawnMoves)
		} else {
			setLegalMoves(receivedMoves);
			setGamePlayers(receivedPlayers);
			setPlacedWalls(receivedPlacedWalls);
			setPawnMoves(receivedPawnMoves);
		}

		if (G.moves.length > 0) {
			if (game.isWallMove(G.moves[G.moves.length-1])) {
				playWallSound();
			} else if (game.isPawnMove(G.moves[G.moves.length-1])) {
				playPawnSound();
			}
		}
		

		animateMovement(game.players);
	}, [G]);

	useEffect(() => {
		if (ctx.gameover && ctx.gameover.winner === playerID) {
			playWinSound();
		}
	}, [ctx.gameover])

	const flipBoard = (receivedMoves, receivedPlayers, receivedPlacedWalls, receivedPawnMoves) => {
		if (receivedMoves) {
			let flippedPlayers = flipPlayers(receivedPlayers)
			setLegalMoves(flipMoves(receivedMoves));
			setGamePlayers(flippedPlayers);
			setPlacedWalls(flipMoves(receivedPlacedWalls));
			setPawnMoves(flipMoves(receivedPawnMoves));
			return
		}

		const receivedMoves2 = flipMoves(legalMoves)
		const receivedPlayers2 = flipPlayers(gamePlayers)
		const receivedPlacedWalls2 = flipMoves(placedWalls)
		const receivedPawnMoves2 = flipMoves(pawnMoves)
		
		setLegalMoves(receivedMoves2);
		setGamePlayers(receivedPlayers2);
		setPlacedWalls(receivedPlacedWalls2);
		setPawnMoves(receivedPawnMoves2);
	}

	const flipMoves = (moves) => {
		const flippedMoves = moves.map((move) => flipMove(move))
		return flippedMoves
	}

	const flipMove = (move) => {
		if (move.type === "p") {
			let flippedNotation;
			if (move.notation) {
				flippedNotation = move.notation[0] + (10-move.notation[1])
			}
			return {type: "p", x: 8-move.x, y: 8-move.y, notation: flippedNotation};
		} else if (move.type === "w") {
			const nr = move.nr <= 63 ? 63-move.nr : 128 + 63 - move.nr

			const boardSize = 9
			const x = nr % (boardSize - 1);
			let y = Math.floor(nr / (boardSize - 1) + 1);
			if (move.dir === "v") {
				y -= boardSize - 1;
			}
			const xStr = String.fromCharCode(97 + x);
			const yStr = 9 - y;
			const notation = xStr + yStr + move.dir;


			return {type: "w", nr, legal: move.legal, placed: move.placed, dir: move.dir, notation};
		}
	}

	const flipPlayers = (playerss) => {
		return [{
			...playerss[0], nr: 0, pos: { x: 8-playerss[0].pos.x, y: 8-playerss[0].pos.y}
		}, {
			...playerss[1], nr: 1, pos: { x: 8-playerss[1].pos.x, y: 8-playerss[1].pos.y}
		}]
	}

	const animateMovement = (players) => {
		if (refs[0].current === null) {
			return;
		}
		setPawnMove(null);

		for (const player of players) {
			let move = { type:"p", x: player.pos.x, y: player.pos.y}
			if (flip) {
				move = flipMove(move)
			}
			const { x, y } = gridToCoords(move.x, move.y);
			refs[player.nr].current.to({
				x: x,
				y: y,
				duration: 0.2,
			});
		}
	};

	const animateLastWall = (wallRef) => {
		if (!wallRef) return;
		
		if (wallRef.attrs.nr < 64) {
			wallRef.to({
				width: 2 * CONSTS.tileSize + CONSTS.tilePadding,
				height: CONSTS.tilePadding,
				fill: CONSTS.COLORS.WALL.PLACED,
				x: wallRef.attrs.x + CONSTS.wallAnimationExtraWidth / 2,
				y: wallRef.attrs.y + CONSTS.wallAnimationExtraHeight / 2,
				duration: CONSTS.wallAnimationDuration
			})
		} else {
			wallRef.to({
				width: CONSTS.tilePadding,
				height: 2 * CONSTS.tileSize + CONSTS.tilePadding,
				fill: CONSTS.COLORS.WALL.PLACED,
				x: wallRef.attrs.x + CONSTS.wallAnimationExtraHeight / 2,
				y: wallRef.attrs.y + CONSTS.wallAnimationExtraWidth / 2,
				duration: CONSTS.wallAnimationDuration
			})
		}
	}

	const drawTiles = () => {
		let boardTiles = []

		for (let i = 0; i < BOARD_SIZE; i++) {
			for (let j = 0; j < BOARD_SIZE; j++) {
				const x = CONSTS.boardX + CONSTS.border + CONSTS.tilePadding / 2 + i * (CONSTS.tileSize + CONSTS.tilePadding);
				const y = CONSTS.boardY + CONSTS.border + CONSTS.tilePadding / 2 + j * (CONSTS.tileSize + CONSTS.tilePadding);

				const id = i * BOARD_SIZE + j;

				const tile = (
					<Rect
						key={id}
						x={x}
						y={y}
						width={CONSTS.tileSize}
						height={CONSTS.tileSize}
						fill={CONSTS.COLORS.TILE.NORMAL}
						// fill={hoverTile[id] ? CONSTS.COLORS.TILE.HOVER : CONSTS.COLORS.TILE.NORMAL}
						// onMouseEnter={() => setHoverTile({...hoverTile, [id]: true})}
						// onMouseLeave={() => setHoverTile({...hoverTile, [id]: false})}
					/>
				);
				boardTiles.push(tile);
			}
		}
		setTiles([boardTiles])
	};

	const drawPlayers = (players) => {
		let tempPlayers = []
		for (const player of players) {
			const { x, y } = gridToCoords(player.pos.x, player.pos.y);
			const radius = CONSTS.playerSize / 2;
			const pawn = (
				<Circle
					ref={refs[player.nr]}
					key={player.nr*42}
					x={x}
					y={y}
					radius={radius}
					fill={CONSTS.COLORS.PLAYERS[player.nr]}
				/>
			);
			tempPlayers.push(pawn)
		}
		setPlayers(tempPlayers)
	};

	const drawPawnMoves = (move) => {
		const { x, y } = gridToCoords(move.x, move.y);
		const id = move.x * BOARD_SIZE + move.y;
		if (!hoverTile[id]) {
			setHoverTile({ ...hoverTile, [id]: true });
		}
	};

	const drawShadowPlayer = (move) => {
		const { x, y } = gridToCoords(move.x, move.y);

		// console.log(move)
		const radius = CONSTS.playerSize / 2;
		// players[player.nr] = pawn;
		const playerNr = parseInt(ctx.currentPlayer);
		const shadowPlayer = <Circle key={playerNr} x={x} y={y} radius={radius} fill={CONSTS.COLORS.PLAYERS[playerNr]} opacity={0.3} i={move.x} j={move.y} />;
		setPawnMove(shadowPlayer);
	};

	const drawShadowWall = (wallNr) => {
		// console.log('wallNr:', wallNr);
		const { wallX, wallY } = wallNrToCoords(wallNr);
		// console.log('wallY:', wallY);
		// console.log('wallX:', wallX);
		let rect;
		if (wallNr < 64) {
			rect = (
				<Rect
					key={wallNr}
					x={wallX}
					y={wallY}
					width={2 * CONSTS.tileSize + CONSTS.tilePadding}
					height={CONSTS.tilePadding}
					fill={CONSTS.COLORS.WALL.SHADOW}
					cornerRadius={CONSTS.wallRadius}
				/>
			);
		} else {
			rect = (
				<Rect
					key={wallNr}
					x={wallX}
					y={wallY}
					width={CONSTS.tilePadding}
					height={2 * CONSTS.tileSize + CONSTS.tilePadding}
					fill={CONSTS.COLORS.WALL.SHADOW}
					cornerRadius={CONSTS.wallRadius}
				/>
			);
		}
		// shadowWall = rect;
		setShadowWall(rect);
	};

	const drawWalls = (placedWalls) => {
		// console.log('wallNr:', wallNr);
		// console.log('wallY:', wallY);
		// console.log('wallX:', wallX);

		const tempWalls = []
		for (const wall of placedWalls) {
			const { wallX, wallY } = wallNrToCoords(wall.nr);
			let rect;

			let shouldAnimate = false;
			let extraWidth = 0
			let extraHeight = 0
			let fill = CONSTS.COLORS.WALL.PLACED

			const lastMove = G.moves[G.moves.length-1]
			if (lastMove.type === "w" && lastMove.nr == wall.nr) {
				shouldAnimate = true
				extraWidth = CONSTS.wallAnimationExtraWidth
				extraHeight = CONSTS.wallAnimationExtraHeight
				fill = CONSTS.COLORS.WALL.SPAWN
				
				if (wall.nr >= 64) {
					extraWidth = CONSTS.wallAnimationExtraHeight
					extraHeight = CONSTS.wallAnimationExtraWidth
				}
			}
		
			if (wall.nr < 64) {
				rect = (
					<Rect
						ref={newRef => {
							if (shouldAnimate) {
								animateLastWall(newRef)
							} 
						}}
						key={wall.nr}
						x={wallX - extraWidth / 2}
						y={wallY - extraHeight / 2}
						width={2 * CONSTS.tileSize + CONSTS.tilePadding + extraWidth}
						height={CONSTS.tilePadding + extraHeight}
						fill={fill}
						cornerRadius={CONSTS.wallRadius}
						nr={wall.nr}
					/>
				);
			} else {
				rect = (
					<Rect
						ref={newRef => {
							if (shouldAnimate) {
								animateLastWall(newRef)
							} 
						}}
						key={wall.nr}
						x={wallX - extraWidth / 2}
						y={wallY - extraHeight / 2}
						width={CONSTS.tilePadding + extraWidth}
						height={2 * CONSTS.tileSize + CONSTS.tilePadding + extraHeight}
						fill={fill}
						cornerRadius={CONSTS.wallRadius}
						nr={wall.nr}
					/>
				);
			}
			tempWalls.push(rect)
		}
		setWalls(tempWalls)
	};

	const gridToCoords = (i, j) => {
		const x = CONSTS.boardX + CONSTS.border + CONSTS.tilePadding / 2 + i * (CONSTS.tileSize + CONSTS.tilePadding);
		const y = CONSTS.boardY + CONSTS.border + CONSTS.tilePadding / 2 + j * (CONSTS.tileSize + CONSTS.tilePadding);
		return { x: x + CONSTS.tileSize / 2, y: y + CONSTS.tileSize / 2 };
	};

	const coordsToTileGrid = (originalX, originalY) => {
		const x = originalX - CONSTS.border - CONSTS.boardX;
		const y = originalY - CONSTS.border - CONSTS.boardY;

		const i = Math.floor(x / (CONSTS.tileSize + CONSTS.tilePadding));
		const j = Math.floor(y / (CONSTS.tileSize + CONSTS.tilePadding));

		const i2 = x % (CONSTS.tileSize + CONSTS.tilePadding);
		const j2 = y % (CONSTS.tileSize + CONSTS.tilePadding);

		if (
			i >= 0 &&
			i <= 8 &&
			j >= 0 &&
			j <= 8 &&
			i2 > CONSTS.tilePadding / 2 &&
			i2 < CONSTS.tileSize + CONSTS.tilePadding / 2 &&
			j2 > CONSTS.tilePadding / 2 &&
			j2 < CONSTS.tileSize + CONSTS.tilePadding / 2
		) {
			return { i: i, j: j };
		} else {
			return { i: null, j: null };
		}
	};

	const wallNrToCoords = (nr) => {
		// console.log('nr:', nr);
		let i;
		let j;
		if (nr < 64) {
			i = nr % (BOARD_SIZE - 1);
			j = Math.floor(nr / (BOARD_SIZE - 1));
		} else {
			i = nr % (BOARD_SIZE - 1);
			j = Math.floor((nr - 64) / (BOARD_SIZE - 1));
		}

		const { x: tileX, y: tileY } = gridToCoords(i, j + 1);
		let wallX;
		let wallY;

		if (nr < 64) {
			wallX = tileX - CONSTS.tileSize / 2;
			wallY = tileY - CONSTS.tileSize / 2 - CONSTS.tilePadding;
		} else {
			wallX = tileX + CONSTS.tileSize / 2;
			wallY = tileY - CONSTS.tileSize / 2 - CONSTS.tilePadding - CONSTS.tileSize;
		}

		return { wallX, wallY };
	};

	const coordsToWall = (x, y) => {
		x = x - CONSTS.border - CONSTS.boardX - CONSTS.tilePadding / 2;
		y = y - CONSTS.border - CONSTS.boardY + CONSTS.tilePadding / 2;

		let i = Math.floor(x / (CONSTS.tileSize + CONSTS.tilePadding));
		let j = Math.floor(y / (CONSTS.tileSize + CONSTS.tilePadding)) - 1;
		// console.log('i:', i);
		// console.log('j:', j);

		const i2 = x % (CONSTS.tileSize + CONSTS.tilePadding);
		const j2 = y % (CONSTS.tileSize + CONSTS.tilePadding);
		// console.log('i2:', i2);
		// console.log('j2:', j2);

		if (i >= 0 && i <= 7 && j >= 0 && j <= 7) {
			// console.log('j:', j);
			// console.log('j >= 1:', j >= 1);
			if (i2 >= 0 && j2 >= 0 && j2 <= CONSTS.tilePadding) {
				if (i2 <= CONSTS.tileSize) {
					return j * 8 + i;
				}
				else {
					return { prev: true, i, j}
				}
			} else if (
				i2 >= CONSTS.tileSize &&
				i2 <= CONSTS.tileSize + CONSTS.tilePadding &&
				j2 >= CONSTS.tilePadding &&
				j2 <= CONSTS.tileSize + CONSTS.tilePadding
			) {
				return j * 8 + i + 64;
			}
		}
		return null;
	};

	const handleStageMouseOver = (e) => {
		const x = e.evt.offsetX;
		const y = e.evt.offsetY;

		const { i, j } = coordsToTileGrid(x, y);

		if (i !== null && j !== null) {
			// setPawnMove({ type: "p", x: i, y: j });
			if (!(pawnMove && pawnMove.props.i === i && pawnMove.props.j === j) && pawnMoves.some((move) => move.x === i && move.y === j)) {
				drawShadowPlayer({ type: "p", x: i, y: j });
			}
		} else {
			if (pawnMove) {
				setPawnMove(null);
			}
		}

		const wall = coordsToWall(x, y);
		// console.log("wall", wall);
		// console.log("x", x);
		// console.log("y", y);
		// console.log("wall", wall);

		if (wall && wall.prev && shadowWall) {
			if (shadowWall.key < 63) {
				const actual = wall.j * 8 + wall.i;
				if (actual !== parseInt(shadowWall.key)) {
					const notation = makePretty({ nr: actual });
					if (!(shadowWall && shadowWall.key == actual) && legalMoves.some((move) => move.notation === notation)) {
						drawShadowWall(actual);
					}
				}
			} else {
				const actual = wall.j * 8 + wall.i + 64;
				if (actual !== parseInt(shadowWall.key)) {
					const notation = makePretty({ nr: actual });
					if (!(shadowWall && shadowWall.key == actual) && legalMoves.some((move) => move.notation === notation)) {
						drawShadowWall(actual);
					}
				}
			}
			return
		}

		if (wall !== null) {
			if (shadowWall && shadowWall.key == wall) {
				return
			}
			// console.log('wall:', wall);
			const notation = makePretty({ nr: wall });

			// console.log('notation:', notation);
			// console.log(`legalMoves`, legalMoves)

			if (legalMoves.some((move) => move.notation === notation)) {
				drawShadowWall(wall);
			} else {
				if (wall < 64) {
					if (shadowWall && shadowWall.key == wall - 1) {
						return
					}
					const wallToTheLeft = makePretty({ nr: wall - 1 });
					if (legalMoves.some((move) => move.notation === wallToTheLeft)) {
						drawShadowWall(wall-1);
					} else {
						if (shadowWall) {
							setShadowWall(null);
						}
					}
				} else {
					if (shadowWall && shadowWall.key == wall + 8) {
						return
					}

					const wallBelow = makePretty({ nr: wall + 8 });
					if (legalMoves.some((move) => move.notation === wallBelow)) {
						drawShadowWall(wall+8);
					} else {
						if (shadowWall) {
							setShadowWall(null);
						}

					}
				}
			}
		} else {
			if (shadowWall) {
				setShadowWall(null);
			}

		}
		// console.log('usual click on ', e.target.attrs.x, e.target.attrs.y );
	};

	const handleStateMouseClick = (e) => {
		if (!playerID && isMultiplayer) return;

		if (pawnMove) {
			const { i, j } = coordsToTileGrid(pawnMove.props.x, pawnMove.props.y);
			let uglyMove = { type: "p", x: i, y: j }
			if (flip) {
				uglyMove = flipMove(uglyMove)
			}

			moves.move(uglyMove);
		}
		if (shadowWall) {
			const dir = shadowWall.key < 64 ? "h" : "v";
			let move = { nr: shadowWall.key, dir: dir, type: "w" }
			if (flip) {
				move = flipMove(move)
			}
			moves.move(move);
		}
	};

	// if (players[0] === null) {

		// gamePlayers.forEach((player) => {
		// 	drawPlayer(player);
		// });
		
	// }

	// pawnMoves.forEach((move) => {
	// 	drawPawnMoves(move);
	// });



	// drawTiles();

	const playerBox = (name, wallsLeft, white) => (
		<div className="board__player-box">
		<div className="board__container">
			{white ? <BsPersonFill color={ctx.currentPlayer === "0" ? "#f3c536" : "white"} /> : <BsPerson color={ctx.currentPlayer === "1" ? "#f3c536" : "white"}/>} <span className="board__player-name">{name}</span>
		</div>
		<div className="board__walls-left board__container">
			<div className="board__wall-icon">
				<GiBrickWall className="board_wall-icon" size={18} />
			</div> <span>{gamePlayers.length > 0 && wallsLeft}</span>
		</div>
	</div>
	)

	if (!matchData) {
		matchData = [{ name: "White player"}, { name: "Black player"}]
	}

	const playerBoxes = gamePlayers.map((player, ind) => playerBox(matchData[player.nr].name, player.wallsLeft, ind===0)) 
	if (!flip) {
		playerBoxes.reverse();
	}

	return (
		<div className="board__container">
			<div>
			{playerBoxes.length > 0 && playerBoxes[0]}

				<div className="board">
					<Stage
						width={CONSTS.windowWidth}
						height={CONSTS.windowHeight}
						onMouseMove={handleStageMouseOver}
						onMouseDown={handleStateMouseClick}
					>
						<Layer>
							{tiles}
							{/* <Rect
											x={20}
											y={20}
											width={50}
											height={50}
											fill="red"
										/> */}
							{players}
							{isActive && shadowWall}
							{isActive && pawnMove}
							{walls}
						</Layer>
					</Stage>
				</div>
				{playerBoxes.length > 0 && playerBoxes[1]}

			</div>
			<div className="board__sidebar">
				<div style={{ width: "100%"}}>
				{ctx.gameover && ctx.gameover.hasResigned && <h2 className="board__resigned-text">{ctx.gameover.winner === "1" ? "White" : "Black"} resigned</h2>}
				{ctx.gameover && <h2 className="board__winner-text">{ctx.gameover.winner === "0" ? "White" : "Black"} wins!</h2>}
					<Container maxWidth="sm">
						<div className="board__chat-box">
							<div className="board__chat-couter-wrapper">
								<div className="board__chat-messages">
									{chatMessages.map((chatObject) => {
										return <div className="board__chat"><span className="board__chat-sender">{matchData[chatObject.sender].name}:</span> <span className="board__chat-payload">{chatObject.payload}</span></div>
									})}
								</div>
							</div>
							<div className="board__chat-input">
							{/* <form> */}
								<TextField
										value={chat}
										onChange={(e) => setChat(e.target.value)}
										// className="landing-page__nickname"
										id="outlined-basic"
										// label="Nickname"
										placeholder="Message..."
										// variant="outlined"
										size="small"
										onKeyPress={(ev) => {
											if (ev.key === 'Enter') {
												sendChatMessage(chat)
												setChat("");
												ev.preventDefault();
											}
										  }}
									/>
							{/* </form> */}
								{/* <Grid container spacing={1} alignItems="flex-end">
									<Grid item style={{ marginBottom: "0.8rem" }}>
										<BsFillChatSquareFill size={18} />
									</Grid>
									<Grid item>
										<TextField
											// value={nickname}
											// onChange={(e) => setNickname(e.target.value)}
											// className="landing-page__nickname"
											id="outlined-basic"
											// label="Nickname"
											placeholder="Message..."
											// variant="outlined"
											size="small"
										/>
									</Grid>
								</Grid> */}

							</div>
						</div>
						
					</Container>
					<div className="board__button-container">
						<Button
							onClick={() => {}}
							className="board__sidebar-button"
							variant="contained"
							color="default"
							onClick={() => {
								if (playerID !== ctx.currentPlayer) {
									alert("Can only resign when it's your turn. I'm working on fixing this.")
								}
								events.endGame({ winner: playerID === "0" ? "1" : "0", hasResigned: true })
							}}
						>
							<BsFlag />
						</Button>
					<Button
						onClick={() => {}}
						className="board__back-button board__sidebar-button"
						variant="contained"
						color="default"
						onClick={() => setPage("lobby")}
					>
						Back to lobby
					</Button>
					</div>
					{/* <Button
						className="board__back-button"
						variant="contained"
						color="default"
						onClick={() => {
							setFlip(!flip)
							flipBoard()
						}}
					>
						Flip
					</Button> */}
				</div>
			</div>
		</div>
	);
};
