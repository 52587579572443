// import React from 'react'
// import { Quoridor } from './quoridor';
// import { QuoridorBoard } from './board';
// import { Client } from 'boardgame.io/react';
// import { Local } from "boardgame.io/multiplayer";
// import { SocketIO } from 'boardgame.io/multiplayer'

const isVictory = (G, ctx) => {
    if (G.moves.length === 0) return false;
    const lastMove = G.moves[G.moves.length - 1]
    return (lastMove.type === "p" && (lastMove.y ===  0 && G.moves.length % 2 === 1 || lastMove.y ===  8 && G.moves.length % 2 === 0));
}

const QuoridorGame = {
    name: 'Quoridor',
    minPlayers: 2,
    maxPlayers: 2,

    setup: () => ({ moves: [] }),
    
    turn: {
        moveLimit: 1,
      },

    moves: {
        move: (G, ctx, move) => {
            G.moves.push(move);
        },
    },

    endIf: (G, ctx) => {
        if (isVictory(G, ctx)) {
            return { winner: ctx.currentPlayer };
        }
      },
};

export default QuoridorGame;