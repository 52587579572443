import React from "react";
// import { Quoridor } from './quoridor';
import QuoridorGame from "./game";
import { QuoridorBoard } from "./board";
import { Client } from "boardgame.io/react";
import { Local } from "boardgame.io/multiplayer";
import { SocketIO } from "boardgame.io/multiplayer";
import { Lobby } from "boardgame.io/react";

// export const Main = () => {
//     const game = new Quoridor();
//     // console.log(game);
//     return (
//         <div>
//             <QuoridorBoard game={game} />
//         </div>
//     )
// }

// const game = new Quoridor()

// const quoridor2 = {
//     setup: () => ({ board: initBoard() }),
//     moves: {
//         movePawn: (G, ctx, move) => {
//             tryMovePretty(move)
//         },
//     },
// };

// const QuoridorClient = Client({ game: quoridor, board: QuoridorBoard, multiplayer: SocketIO({ server: 'localhost:8000' }), debug: false });

// var randomnumber = Math.floor(Math.random() * (1 - 0 + 1)) + 0;
// console.log("randomnumber", randomnumber);

// export const Main = Client({ game: quoridor, board: QuoridorBoard, multiplayer: Local(), debug: false });

const QuoridorClient = Client({ game: QuoridorGame, board: QuoridorBoard, debug: false });
export const Main = () => {
	console.log("window.location.hostname", window.location.hostname);
	return (
		<div>
			{/* <h2 style={{ marginTop: "20px", marginBottom: "15px" }}>Quoridor.io</h2>
        <Lobby
          gameServer={`http://127.0.0.1:8020`}
          lobbyServer={`http://127.0.0.1:8020`}
          // gameServer={`https://quoridorio.herokuapp.com${process.env.PORT}`}
          // lobbyServer={`https://quoridorio.herokuapp.com${process.env.PORT}`}
          gameComponents={[
            { game: QuoridorGame, board: QuoridorBoard }
          ]}
        />; */}
			<QuoridorClient />
			<br />
			{/* Player 2 */}
			{/* <QuoridorClient playerID="1" /> */}
			<br />
		</div>
	);
};

// const { protocol, hostname, port } = window.location;
// const server = `${protocol}//${hostname}:${port}`;
// const importedGames = [{ game: QuoridorGame, board: QuoridorBoard }];

// export const Main = () => (
//   <div>
//     <h1>Lobby</h1>
//     <Lobby gameServer={server} lobbyServer={server} gameComponents={importedGames} />
//   </div>
// );
