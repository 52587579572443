import React, { useState, useEffect, useRef } from "react";
import { Button, CircularProgress } from "@material-ui/core";
import { BsPerson } from "react-icons/bs";
import "./Lobby.css";
import posed, { PoseGroup } from "react-pose";
import { LobbyClient } from "boardgame.io/client";
import useSound from 'use-sound';
import matchReadySound from '../../../Assets/ready.mp3';


export const Lobby = ({ nickname, startMatch, setCredentials, credentials }) => {
	const [matches, setMatches] = useState([
		// {
		// 	p1: "Arne",
		// },
		// {
		// 	p1: "Gunnar",
		// 	p2: "Peder",
		// },
	]);
	const [client, setClient] = useState(null);
	const [currentMatch, setCurrentMatch] = useState(null);
	const [loading, setLoading] = useState(true);
	const [playMatchReadySound] = useSound(matchReadySound, { volume: 0.6 });

	const prevMatches = usePrevious(matches);


	let { protocol, hostname, port } = window.location;
	if (hostname === "localhost") {
		port = 8020;
	}
	// const { protocol, hostname } = window.location;
	const server = `${protocol}//${hostname}:${port}`;
	// const server = "http://127.0.0.1:8020";

	useEffect(() => {
		const lobbyClient = new LobbyClient({ server });
		setClient(lobbyClient);
		console.log("lobbyClient", lobbyClient);
	}, []);

	useEffect(() => {
		refresh();

		setInterval(async () => {
			refresh();
		}, 3000);
	}, [client]);

	// Play sound effect when match is ready
	useEffect(() => {
		if (!prevMatches) return;
		let readyMatches = 0
		let prevReadyMatches = 0

		for (const match of matches) {
			const p1 = match.players[0].name;
			const p2 = match.players[1].name;
			const playerIsInMatch = nickname === match.players[0].name || nickname === match.players[1].name;
			const matchIsFull = p1 && p2;
			if (playerIsInMatch && matchIsFull) {
				readyMatches += 1
			}
		}

		for (const match of (prevMatches || [])) {
			const p1 = match.players[0].name;
			const p2 = match.players[1].name;
			const playerIsInMatch = nickname === match.players[0].name || nickname === match.players[1].name;
			const matchIsFull = p1 && p2;
			if (playerIsInMatch && matchIsFull) {
				prevReadyMatches += 1
			}
		}

		if (readyMatches > prevReadyMatches) {
			playMatchReadySound();
			if (document.hidden) {
				document.title=`(1) ${document.title}`
			}
		}
	}, [matches]);

	window.onfocus = function () { 
		document.title="Quoridor.io - Play Quoridor online"
	  }; 

	function usePrevious(value) {
		const ref = useRef();
		useEffect(() => {
		  ref.current = value;
		});
		return ref.current;
	  }

	const refresh = async () => {
		if (!client) return;

		try {
			const { matches } = await client.listMatches("Quoridor");
			setMatches(matches);
		} catch (error) {
			console.log("error", error);
		}

		// const responseToRooms = matches.map((match) => {
		//     const p1 = match.players[0].name
		//     const p2 = match.players[1].name
		//     return { p1, p2 }
		// })
		// console.log("responseToRooms", responseToRooms);
		setLoading(false);
	};

	const leaveCurrentMatch = async () => {
		if (currentMatch) {
			const yourIdInCurrentMatch = nickname === currentMatch.players[0].name ? "0" : "1";
			await client.leaveMatch("Quoridor", currentMatch.matchID, {
				playerID: yourIdInCurrentMatch,
				credentials: credentials,
			});
		}
	};

	const Lel = posed.li({
		enter: {
			opacity: 1,
			x: 0,
		},
		exit: {
			x: 100,
			opacity: 0,
			transition: { duration: 300 },
		},
	});

	const Match = (match) => {
		if (!match) return;
		const p1 = match.players[0].name;
		const p2 = match.players[1].name;

		const playerIsInMatch = nickname === match.players[0].name || nickname === match.players[1].name;
		const matchIsFull = p1 && p2;
		const yourID = nickname === match.players[0].name ? "0" : "1";
		const opponentID = nickname === match.players[0].name ? "1" : "0";

		const person = (p) => {
			if (p) {
				return (
					<span className="lobby__person">
						<BsPerson />
						<span className="lobby__person-name">{p}</span>
					</span>
				);
			} else {
				return (
					<span className="lobby__person lobby__person--free">
						<BsPerson />
						<span>Free</span>
					</span>
				);
			}
		};

		return (
			// <div className="lobby__room-container2">
			<>
				{person(p1)}
				<span className="lobby__vs">VS</span>
				{person(p2)}
				{!matchIsFull && !playerIsInMatch && (
					<div className="lobby__join-button">
						<Button
							onClick={async () => {
								if (playerIsInMatch) return;
								const id = match.players[0].name ? "1" : "0";

								leaveCurrentMatch();

								const { playerCredentials } = await client.joinMatch("Quoridor", match.matchID, {
									playerID: id,
									playerName: nickname,
								});

								setCurrentMatch(match);

								await refresh();
								setCredentials(playerCredentials);
							}}
							variant="contained"
							color="secondary"
						>
							Join
						</Button>
					</div>
				)}
				<div style={{ display: "flex" }}>
					{playerIsInMatch && matchIsFull && (
						<div className="lobby__join-button">
							<Button
								onClick={async () => {
									startMatch(match);
								}}
								variant="contained"
								color="secondary"
								style={{ backgroundColor: "green", color: "#FFFFFF" }}
							>
								Start
							</Button>
						</div>
					)}
					{!playerIsInMatch && matchIsFull && (
						<div className="lobby__join-button">
							<Button
								onClick={async () => {
									startMatch(match);
								}}
								variant="contained"
								color="default"
							>
								Spectate
							</Button>
						</div>
					)}
					{playerIsInMatch && (
						<div className="lobby__join-button">
							<Button
								onClick={async () => {
									await leaveCurrentMatch();
									setCredentials(null);
									setCurrentMatch(null);
									await refresh();
								}}
								variant="outlined"
								color="default"
							>
								Leave
							</Button>
						</div>
					)}
				</div>
				{p1 && p2 && (
					<></>
					// <Button variant="outlined" color="disabled">
					// 	Join
					// </Button>
				)}
			</>
			// </div>
		);
	};

	return (
		<div>
			<h1 className="lobby__title">Lobby</h1>
			<hr></hr>
			<PoseGroup animateOnMount>
				{matches.map((match) => {
					return (
						<Lel className="lobby__rooms-container" key={match.matchID}>
							{Match(match)}
						</Lel>
					);
				})}
			</PoseGroup>
			<div className="lobby__button-container">
				<div className="lobby__button">
					<Button
						onClick={async () => {
							try {
								leaveCurrentMatch();

								const { matchID } = await client.createMatch("Quoridor", {
									numPlayers: 2,
								});

								const { playerCredentials } = await client.joinMatch("Quoridor", matchID, {
									playerID: "0",
									playerName: nickname,
								});
								setCredentials(playerCredentials);
								setCurrentMatch({
									matchID: matchID,
									players: [{ id: 0, name: nickname }],
								});
								console.log("matchID", matchID);
								await refresh();
							} catch (error) {
								console.log("error", error);
								return;
							}
						}}
						variant="contained"
						color="primary"
					>
						Create
					</Button>
				</div>
				<div className="lobby__button">
					<Button
						onClick={() => {
							setLoading(true);
							refresh();
						}}
						variant="outlined"
						color="default"
					>
						{loading ? <CircularProgress size={20} /> : <span>Refresh</span>}
					</Button>
				</div>
			</div>
		</div>
	);
};
